import { Routes, Route, useLocation } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import About from "./components/about/about";
import Home from "./components/home/home";
import Layout from "./components/layout/layout";
import Test from "./components/test/test";

function App() {
	const location = useLocation()
	const transitions = useTransition(location, {
		from: {display: 'none'},
		enter: {display: 'block'},
		leave: {display: 'none'},
		// from: {opacity: 0, transform: 'translateY(100%)'},
		// enter: {opacity: 1, transform: 'translateY(0%)'},
		// leave: {opacity: 0, transform: 'translateY(-100%)'},
		// config: {duration: 350}
		// from: {opacity: 0, animation: 'fadeInRight 1s forwards'},
		// enter: {opacity: 1},
		// leave: {opacity: 0, animation: 'fadeOutLeft 1s forwards'},
		
		})

	return transitions(
		(props, item) => (
			<animated.div className='content' style={{...props}}>
				<Routes location={item}>
					<Route path="/" element={<Layout />}>
						<Route index element={<Home />} />
						<Route path="about" element={<About />} />
						<Route path="test" element={<Test />} />
					</Route>
				</Routes>
			</animated.div>
		)
	)
}

export default App;
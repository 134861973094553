import script from "../../images/about/script.svg"
import web from "../../images/about/web.svg"
import bot from "../../images/about/bot.svg"


function About() {
    return (
        <section className="about">
            <div className="container">
                <div className="about__wrapper">
                    <div className="description">
                        <h2 className="description__title">Обо мне</h2>
                        <div className="description__subtitle">
                            Меня зовут Ярослав
                        </div>
                        <div className="divider divider--about" />
                        <div className="description__text">
                            <p>и я опытный веб-разработчик с опытом работы в HTML, CSS, JavaScript, React, Python, Django, Django Rest Framework, Fast API, SQL и методологии BEM. Обладая разнообразными знаниями и опытом, я могу помочь вам в создании потрясающих веб-сайтов, разработке эффективных скриптов и создании ботов для различных мессенджеров, таких как Telegram.</p>
                            <p>Благодаря моим знаниям HTML, CSS, JavaScript, React, Python, Django, Django Rest Framework, Fast API, SQL и методологии BEM я могу воплотить ваши идеи в жизнь и предложить высококачественные решения, адаптированные к вашим конкретным потребностям. Я стремлюсь к вниманию к деталям, своевременной доставке и обеспечению того, чтобы ваш веб-сайт или скрипт соответствовали вашим ожиданиям.</p>
                            <p>Давайте вместе создадим веб-сайт, который продемонстрирует ваш бренд, разработаем скрипт, который упростит ваши рутинные дела, или создадим бота, который улучшит вашу коммуникацию. Свяжитесь со мной любым удобным для вас способом, и давайте воплотим ваше видение в реальность!</p>
                        </div>
                    </div>
                    <div className="skills">
                        <div className="skills__item">
                            <div className="skills__title">
                                <div className="skills__icon"><img src={script} alt="web" /></div>
                                <h3>Разработка скриптов</h3>
                            </div>
                            <div className="divider divider--skills_item" />
                            <div className="skills__text">
                                <p>В области написания скриптов на Python у меня есть возможность писать различные скрипты, которые могут автоматизировать широкий спектр рутинных задач. Будь то обработка данных, манипулирование файлами или любая другая повторяющаяся задача, я могу разработать скрипты на Python, которые сэкономят вам время и усилия.</p>
                            </div>
                        </div>
                        <div className="skills__item">
                            <div className="skills__title">
                                <div className="skills__icon"><img src={web} alt="web" /></div>
                                <h3>Web разработка</h3>
                            </div>
                            <div className="divider divider--skills_item" />
                            <div className="skills__text">
                                <p>Когда дело доходит до разработки веб-сайтов, мои навыки распространяются на различные типы сайтов, включая одностраничные приложения (SPA) и прогрессивные веб-приложения (PWA). Я могу создавать динамичные и интерактивные веб-сайты, которые обеспечивают плавный пользовательский интерфейс на разных устройствах.</p>
                            </div>
                        </div>
                        <div className="skills__item">
                            <div className="skills__title">
                                <div className="skills__icon"><img src={bot} alt="web" /></div>
                                <h3>Разработка ботов</h3>
                            </div>
                            <div className="divider divider--skills_item" />
                            <div className="skills__text">
                                <p>Кроме того, я специализируюсь на создании пользовательских ботов для Telegram и других мессенджеров. Эти боты могут помочь вам автоматизировать процессы, обеспечить поддержку клиентов или донести информацию до вашей аудитории персонализированным и эффективным способом.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }
  
export default About;
import Loader from "react-loaders";

function Test() {
    return (
        <section className="test">
            <div className="container">
                <div className="test__upper">
                    "Привет! Я опытный веб-разработчик с опытом работы в HTML, CSS, JavaScript, React, Python, Django, Django Rest Framework, Fast API, SQL и методологии BEM. Обладая разнообразными знаниями и опытом, я могу помочь вам в создании потрясающих веб-сайтов и разработке эффективных скриптов и ботов.
                </div>
                <div className="test__lower">
                    Независимо от того, нужен ли вам профессиональный веб-сайт или пользовательский сценарий, у меня есть навыки, чтобы воплотить ваши идеи в жизнь. Я специализируюсь на создании адаптивных и удобных для пользователя веб-сайтов с использованием HTML, CSS и JavaScript. Кроме того, я могу использовать возможности React для создания динамичных и интерактивных пользовательских интерфейсов веб-приложений, мобильных устройств и настольных приложений.
                </div>
            </div>
        </section>
    );
  }
  
export default Test;
import { NavLink, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faVk, faGithub, faWhatsapp, faTelegram } from '@fortawesome/free-brands-svg-icons';

function Sidebar() {
	return (
		<div className="nav-bar">
			<nav>
				<Link exact='true' activeclassname='active' id='home-link' className='menu-link' to='/'>
					<FontAwesomeIcon icon={faHome} color='$sidebarIcon' />
				</Link>

				<Link exact='true' activeclassname='active' id='about-link' className='menu-link' to='about'>
					<FontAwesomeIcon icon={faUser} color='$sidebarIcon' />
				</Link>

				<Link exact='true' activeclassname='active' id='about-link' className='menu-link' to='test' >
					<FontAwesomeIcon icon={faEnvelope} color='$sidebarIcon' />
				</Link>
			</nav>

			<ul>
				<li>
					<a target="_blank" rel="noreferrer" href="https://vk.com/cornholio">
						<FontAwesomeIcon icon={faVk} color='$sidebarIcon' />
					</a>
				</li>
				<li>
					<a target="_blank" rel="noreferrer" href="https://github.com/Cornholius">
						<FontAwesomeIcon icon={faGithub} color='$sidebarIcon' />
					</a>
				</li>
				<li>
					<a target="_blank" rel="noreferrer" href="https://wa.me/79052203150">
						<FontAwesomeIcon icon={faWhatsapp} color='$sidebarIcon' />
					</a>
				</li>
				<li>
					<a target="_blank" rel="noreferrer" href="https://t.me/YarCorn">
						<FontAwesomeIcon icon={faTelegram} color='$sidebarIcon' />
					</a>
				</li>
			</ul>
		</div>
	);
}
  
export default Sidebar;